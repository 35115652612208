//import ' react-app-polyfill/ie11';
//import ' react-app-polyfill/stable';
import React from 'react';
import { createRoot } from 'react-dom/client';

import { Route, Link, BrowserRouter as Router, Routes } from 'react-router-dom'
import './index.css';
import App from './App';
import PprEFHF from './PprEFHF';
import Tc from './tc/Tc';
import PermissionRequest from './request/permission-request'
import PermissionStatus from './request/permission-status'
import TrafficCiruitStatus from './tc/TcStatus'
import * as serviceWorker from './serviceWorker';
import PrivateView from './private/private-view'
import PublicDataView from './publicdata/publicdataview'
import PrivacyPolicy from './request/privacypolicy'
import 'bootstrap/dist/css/bootstrap.min.css';

const rootElement = document.getElementById("root");

const routing = (
    <Router>
      <Routes>
      <Route exact path="/ui" element={<App />} />
        <Route exact path="/ui/efnu" element={<App defaultAirport="EFNU" />}  />
        <Route exact path="/ui/efik" element={<App defaultAirport="EFIK" />}   />
        <Route exact path="/ui/efhv" element={<App defaultAirport="EFHV" />}   />
        <Route exact path="/ui/efla" element={<App defaultAirport="EFLA" />}   />
        <Route path="/ui/efnu/request"  element={<PermissionRequest defaultAirport="EFNU" />}  />
        <Route path="/ui/efik/request" element={<PermissionRequest defaultAirport="EFIK" />} />
        <Route path="/ui/efhv/request" element={<PermissionRequest defaultAirport="EFHV" />} />
        <Route path="/ui/efla/request" element={<PermissionRequest defaultAirport="EFLA" />} />
        <Route path="/ui/request" element={<PermissionRequest />} />
        <Route path="/ui/requeststatus" element={<PermissionStatus />} />
        <Route path="/ui/tcstatus" element={<TrafficCiruitStatus />} />
        <Route path="/ui/privatedata" element={<PrivateView />} />
        <Route path="/ui/publicdata/efnu" element={<PublicDataView defaultAirport="EFNU" />} />
        <Route path="/ui/publicdata/efhv" element={<PublicDataView defaultAirport="EFHV" />} />
        <Route path="/ui/publicdata/efla" element={<PublicDataView defaultAirport="EFLA" />} />
        <Route path="/ui/privacypolicy" element={<PrivacyPolicy />} />
      </Routes>
    </Router>
  )

  const root = createRoot(document.getElementById('root'));
  root.render(routing)
  //const root = createRoot(rootElement); // createRoot(container!) if you use TypeScript
  //root.render(routing);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
